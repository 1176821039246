import React from 'react';

const ReadBar = ({ width, height, value }) => (
  <svg width={width} height={height}>
    <style>
    {`.bg, .meter { fill: none; stroke-width: ${height}px; stroke-miterlimit: round;}`}
    </style>
    <path className="bg" stroke="#D2797C" d={`M0 ${height / 2}, ${width} ${height / 2}`}></path>
    <path className="meter" stroke="#8DE5A2" d={`M0 ${height / 2}, ${value * width} ${height / 2}`}>
    </path>
  </svg>
);

export default ReadBar;