import { API } from "aws-amplify";

const isValidActivationCode = async activationCode =>
  API.get("mgp", `/activation-codes/${activationCode}`);

const redeemActivationCode = async activationCode =>
  API.post("mgp", `/activation-codes/${activationCode}`);

const loadNumSamples = async () => {
  const result = await API.get("mgp", "/account/sample-balance");
  return result.numSamples;
};

export { isValidActivationCode, redeemActivationCode, loadNumSamples };
