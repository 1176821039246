import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { FormGroup, FormControl, FormLabel } from "react-bootstrap";

import useFormFields from "./hooks/useFormFields";
import LoaderButton from "./components/LoaderButton";
import "./Login.css";

const Login = ({ isAuthenticated, login, history }) => {
  const [isLoading, setLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const validateForm = () =>
    fields.email.length > 0 && fields.password.length > 0;
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await login(fields.email, fields.password);
      history.push("/");
    } catch (err) {
      console.warn(err);
      alert(err.message);
      setLoading(false);
    }
  };
  return (
    !isAuthenticated && (
      <div className="Login">
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="email">
            <FormLabel>Email</FormLabel>
            <FormControl
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="password">
            <FormLabel>Password</FormLabel>
            <FormControl
              value={fields.password}
              onChange={handleFieldChange}
              type="password"
            />
          </FormGroup>
          <LoaderButton
            isLoading={isLoading}
            block
            disabled={!validateForm()}
            type="submit"
          >
            Log in
          </LoaderButton>
          <Link to="/login/reset">Forgot password?</Link>
        </form>
      </div>
    )
  );
};

export default withRouter(Login);
