import React, { useState } from "react";
import {
  FormText,
  FormGroup,
  FormControl,
  FormLabel,
  FormCheck,
} from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";

import useFormFields from "./hooks/useFormFields";
import { isValidActivationCode, redeemActivationCode } from "./activationCodes";
import LoaderButton from "./components/LoaderButton";
import "./Signup.css";

const Signup = ({ signUp, confirmSignUp, login, history }) => {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    activationCode: "",
    confirmationCode: "",
    acceptTerms: false,
  });

  const [isLoading, setLoading] = useState(false);

  const [newUser, setNewUser] = useState(null);

  const validateForm = () =>
    fields.email.length > 0 &&
    fields.password.length > 0 &&
    fields.password === fields.confirmPassword &&
    fields.activationCode.length > 0 &&
    fields.acceptTerms;

  const validateConfirmationForm = () => fields.confirmationCode.length > 0;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const activationCodeValidity = await isValidActivationCode(
        fields.activationCode
      );
      if (!activationCodeValidity.isValid) {
        throw new Error("Invalid activation code");
      }
      const newUser = await signUp(fields.email, fields.password);
      setNewUser(newUser);
    } catch (err) {
      console.warn(err);
      alert(err.message);
    }
    setLoading(false);
  };

  const handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await confirmSignUp(fields.email, fields.confirmationCode, fields.activationCode);
      await login(fields.email, fields.password);
      const activationCodeValidity = await redeemActivationCode(
        fields.activationCode
      );
      if (!activationCodeValidity.isValid) {
        throw new Error("Invalid activation code");
      }
      history.push("/");
    } catch (err) {
      console.warn(err);
      alert(err.message);
      setLoading(false);
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password">
          <FormLabel>Password</FormLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
            autoComplete="new-password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword">
          <FormLabel>Confirm password</FormLabel>
          <FormControl
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
            autoComplete="new-password"
          />
        </FormGroup>
        <FormGroup controlId="activationCode">
          <FormLabel>Activation code</FormLabel>
          <FormControl
            value={fields.activationCode}
            onChange={handleFieldChange}
            pattern="[A-Za-z0-9]+"
          />
          <FormText>Activation code you received with your purchase.</FormText>
        </FormGroup>
        <FormGroup controlId="acceptTerms">
          <FormCheck
            type="checkbox"
            value={fields.acceptTerms}
            onChange={handleFieldChange}
            label={
              <>
                I have read and agree to the{" "}
                <Link to="/terms" target="_blank">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="/privacy" target="_blank">
                  Privacy Policy
                </Link>.
              </>
            }
          ></FormCheck>
        </FormGroup>
        <LoaderButton
          isLoading={isLoading}
          block
          type="submit"
          disabled={!validateForm()}
        >
          Sign up
        </LoaderButton>
      </form>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode">
          <FormLabel>Confirmation code</FormLabel>
          <FormControl
            autoFocus
            value={fields.confirmationCode}
            onChange={handleFieldChange}
            pattern="[A-Za-z0-9]+"
          />
          <FormText>Please check your email for the code.</FormText>
        </FormGroup>
        <LoaderButton
          isLoading={isLoading}
          block
          type="submit"
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  };

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
};

export default withRouter(Signup);
