import React from "react";
import { isNil } from "ramda";

import "./Range.css";

const scale = (value, min, max) => (value - min) / (max - min);

const Range = ({ value, dispMin = 0, refMin, refMax, dispMax = 100, delta = 0.07 }) => {
  const red = "#D2797C";
  const yellow = "#FFE699";
  const green = "#8DE5A2";
  const colorStops = [];
  if (!isNil(refMin) && refMin > dispMin) {
    colorStops.push(
      { color: red, stop: 0 },
      { color: red, stop: Math.max(scale(refMin, dispMin, dispMax) - 2 * delta, 0) }
    );
  }
  if (!isNil(refMin) && !isNil(refMax)) {
    colorStops.push(
      { color: yellow, stop: Math.max(scale(refMin, dispMin, dispMax) - delta, 0) },
      { color: green, stop: scale(refMin, dispMin, dispMax) },
      { color: green, stop: scale(refMax, dispMin, dispMax) },
      { color: yellow, stop: Math.min(scale(refMax, dispMin, dispMax) + delta, 1) }
    );
  }
  if (!isNil(refMax) && refMax < dispMax) {
    colorStops.push(
      { color: red, stop: Math.min(scale(refMax, dispMin, dispMax) + 2 * delta, 1) },
      { color: red, stop: 1 }
    );
  }
  return (
    <input
      disabled
      className="rangecs"
      style={
        colorStops.length > 0
          ? {
              backgroundImage: `-webkit-gradient(
                linear,
                left top,
                right top,
                ${colorStops
                  .map(({ color, stop }) => `color-stop(${stop}, ${color})`)
                  .join(", ")}
              )`,
            }
          : {
            border: "1px solid"
          }
      }
      type="range"
      min={dispMin}
      max={dispMax}
      value={value}
      step="any"
    />
  );
};

export default Range;
;