import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import "./GoogleDoc.css";

const loadContent = async (url, pathname) => {
  const response = await fetch(url);
  const bodyText = await response.text();
  const parsed = new DOMParser().parseFromString(bodyText, "text/html");
  Array.from(parsed.getElementsByTagName("a")).forEach(a => {
    console.log(a.href);
    if (a.getAttribute("href").startsWith("#")) {
      if (pathname) {
        console.log(`rewriting ${a.href}`);
        a.setAttribute("href", "#" + pathname + a.getAttribute("href"));
        console.log(`rewrite to ${a.href}`);
      }
    } else {
      const parsedUrl = new URL(a.href);
      const linkTarget = parsedUrl.searchParams.get("q");
      if (linkTarget) {
        a.href = linkTarget;
        a.rel = "noreferrer noopener";
        a.target = "blank";
      }
    }
  });
  return parsed.body.innerHTML;
};

const GoogleDoc = ({src}) => {
  const [content, setContent] = useState(null);
  const { pathname, hash } = useLocation()
  useEffect(() => {
    (async () => {
      try {
        setContent(await loadContent(src, pathname));
      } catch (err) {
        alert(err);
      }
    })();
  }, [src, pathname]);
  useEffect(() => {
    (async () => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
          element.scrollIntoView();
      }      
    })();
  }, [pathname, hash, content]);
  return (
    <div className="bodytext">
      {content && (<div dangerouslySetInnerHTML={{__html: content}} ></div>)}
    </div>
  );
};

export default GoogleDoc;
