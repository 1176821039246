import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormText,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import {
  isValidActivationCode,
  redeemActivationCode,
  loadNumSamples,
} from "./activationCodes";
import { API, Storage } from "aws-amplify";

const fetchCustomLogo = async () => {
  try {
    const response = await API.get(
      "mgp",
      "/customization/logo"
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const Account = () => {
  const [numSamples, setNumSamples] = useState(null);

  const [activationCode, setActivationCode] = useState("");

  const [customLogo, setCustomLogo] = useState(null);

  const validateForm = () => activationCode.length > 0;

  const loadSamples = async () => {
    setNumSamples(await loadNumSamples());
  };

  const loadCustomLogo = async () => {
    setCustomLogo(await fetchCustomLogo());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let activationCodeValidity = await isValidActivationCode(activationCode);
      if (!activationCodeValidity.isValid) {
        throw new Error("Invalid activation code");
      }
      activationCodeValidity = await redeemActivationCode(activationCode);
      if (!activationCodeValidity.isValid) {
        throw new Error("Invalid activation code");
      }
      await loadSamples();
      setActivationCode("");
    } catch (err) {
      console.warn(err);
      alert(err.message);
    }
  };

  const handleActivationCodeChange = (event) => {
    setActivationCode(event.target.value);
  };
  
  const saveCustomLogo = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const file = event.target.files[0];
      const filename = `custom-logo/${file.name}`
      const putResult = await Storage.vault.put(filename, file, {
        contentType: file.type
      });
      await API.put("mgp", "/customization/logo", {
        body: putResult
      });
    }
    await loadCustomLogo();
  };

  const removeCustomLogo = async () => {
    await API.del("mgp", "/customization/logo");
    await loadCustomLogo();
  };

  useEffect(() => {
    (async () => {
      await loadSamples();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        loadCustomLogo();
      } catch (err) {
        alert(err);
      }
    })();
  }, []);  

  const renderForm = () => {
    return (
      <>
        <h3>Redeem an activation code</h3>
        <p>You can redeem an activation code using the form below.</p>
        <Form onSubmit={handleSubmit}>
          <FormGroup controlId="activationCode" style={{ width: "300px" }}>
            <FormLabel>Activation code</FormLabel>
            <FormControl
              value={activationCode}
              onChange={handleActivationCodeChange}
              pattern="[A-Za-z0-9]+"
            />
            <FormText>
              Activation code you received with your purchase.
            </FormText>
          </FormGroup>
          <Button type="submit" disabled={!validateForm()}>
            Redeem
          </Button>
        </Form>
      </>
    );
  };

  return (
    <>
      <div>
        <h3>Terms of Service</h3>
        <p>
          Read the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
        </p>
      </div>
      <div className="Password">
        <h3>Password</h3>
        <p>
          You can change your password <Link to="/account/password">here</Link>.
        </p>
      </div>
      {customLogo && (
      <div className="CustomLogo">
        <h3>Custom logo</h3>
        <p>You can use a custom logo on the PDF reports.</p>
        <Form>
          {customLogo.name ? (
            <FormGroup controlId="deleteCustomLogo">
              <FormLabel>Logo image</FormLabel>
              <div>
                <Button className="btn btn-secondary" onClick={removeCustomLogo}>Remove</Button>
                {" "}{customLogo.name}
              </div>
            </FormGroup>
          )
          : (
            <FormGroup controlId="customLogo">
              <FormLabel>Logo image</FormLabel>
              <FormControl
                type="file"
                accept=".png,.svg"
                onChange={saveCustomLogo}
              />
              <FormText>
                PNG and SVG images are supported.
              </FormText>
            </FormGroup>
          )}
        </Form>
      </div>
      )}
      <div className="Activation">
        <h3>Account balance</h3>
        <p>You have {numSamples} unused samples remaining on your account.</p>
        {renderForm()}
      </div>
    </>
  );
};

export default withRouter(Account);
