import { chain, unnest, prop, sortBy, splitEvery, uniq } from "ramda";

const separator = /[\W_]+/;

const joiner = "_";

const removeExt = (fn) => fn.replace(/\.fastq(\.gz)?$/, "");

const getPrefix = (prefixLength, fn) =>
  prefixLength
    ? removeExt(fn).split(separator).slice(0, prefixLength).join(joiner)
    : removeExt(fn);

const determinePrefixLength = (fileNameGroups) => {
  const maxPrefixLen = Math.min(
    ...chain(
      (group) => group.map((fn) => removeExt(fn).split(separator).length),
      fileNameGroups
    )
  );
  for (let i = 1; i <= maxPrefixLen; i++) {
    const uniqAcross = fileNameGroups.map((group) =>
      uniq(group.map((fn) => getPrefix(i, fn)))
    );
    const maxUniqs = Math.max(...uniqAcross.map((u) => u.length));
    if (maxUniqs === 1) {
      const globalUniqs = uniq(unnest(uniqAcross));
      if (globalUniqs.length === fileNameGroups.length) {
        return i;
      }
    }
  }
  return 0;
};

const arrangeSamples = (applyPairing, allFiles) => {
  const groupedFiles = splitEvery(
    applyPairing ? 2 : 1,
    sortBy(prop("name"), allFiles)
  );
  const prefixLen = determinePrefixLength(
    groupedFiles.map((l) => l.map((f) => f.name))
  );
  return groupedFiles.map((files) => ({
    files,
    name: getPrefix(prefixLen, files[0].name),
  }));
};

export { removeExt, getPrefix, determinePrefixLength, arrangeSamples };
