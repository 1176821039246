import React from "react";
import { Button } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";

const LoaderButton = ({
  isLoading = false,
  className = "",
  disabled = false,
  ...props
}) => (
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <FaSpinner className="icon-spin" />}
    {" "}
    {props.children}
  </Button>
);

export default LoaderButton;
