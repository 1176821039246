const dev = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "mgp-uploads-dev",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://jlobpevgr2.execute-api.eu-central-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_hoDxXicIi", // "eu-central-1_pqVu1sRfG",
    APP_CLIENT_ID: "46r12tcb3fokl0vaa1dpn6iv3l", // "o6mt7v8glaj56boqijr2q2gcg",
    IDENTITY_POOL_ID: "eu-central-1:6c04a316-b28d-4532-967b-2e9acf2c9c68",  // "eu-central-1:18fe1efa-4a5f-4353-a4de-e6d9e9f84dda",
  },
};

const test = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "mgp-uploads-test",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://nwn058l4ji.execute-api.eu-central-1.amazonaws.com/test",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_YljGm3NsC",
    APP_CLIENT_ID: "4hvb6jn7ftloe70qa5rci3ookv",
    IDENTITY_POOL_ID: "eu-central-1:49f56489-469d-4cf1-946d-37ec3884d9c2",
  },
};

const prod = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "mgp-uploads-prod",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://jxnp8uazka.execute-api.eu-central-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_vdJetcTUq",
    APP_CLIENT_ID: "7fs505mtv6u5icqi4g192jflv1",
    IDENTITY_POOL_ID: "eu-central-1:34833c1d-b294-4bc3-8876-8490dc6ad096",
  },
};

const defaultConfig = {
  s3: {
    REGION: process.env.REACT_APP_REGION,
    BUCKET: process.env.REACT_APP_UPLOADS_BUCKET,
  },
  apiGateway: {
    REGION: process.env.REACT_APP_REGION,
    URL: process.env.REACT_APP_SERVICE_ENDPOINT,
  },
  cognito: {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
};

const config =
  process.env.REACT_APP_STAGE === "test" ? test
  : process.env.REACT_APP_STAGE === "prod" ? prod
  : process.env.REACT_APP_STAGE === "dev" ? dev
    : defaultConfig;

export default config;
