import React from "react";

const NotFound = () => {
  return (
    <>
      <h3>Not found</h3>
      <p>This page has not been implemented yet.</p>
    </>
  );
};

export default NotFound;
